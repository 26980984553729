<template>
    <el-dialog :visible="visible" width="50%" @close="handleClose" @open="handleOpen" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="100px" size="mini">
            <el-row>
                <el-row>
                    <el-row v-if="name">
                        <el-form-item label="上级单位">
                            <span> {{name}}</span>
                        </el-form-item>
                    </el-row>
                    <el-col :span="8">
                        <el-form-item label="名称" prop="name"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model.trim="form.name" :disabled="form.id?true:false"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="简称" prop="short_name">
                            <el-input v-model.trim="form.short_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="英文名称" prop="en_name">
                            <el-input v-model.trim="form.en_name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="单位等级" prop="level"
                                      :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                            <el-select v-model="form.level" placeholder="请选择" style="width:100%" clearable>
                                <el-option :label="item.name" :value="item.id" v-for="(item,index) in levelList"
                                           :key="index" v-show="handelShow(item)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="负责人" prop="pic_name">
                            <el-input v-model.trim="form.pic_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责人电话" prop="pic_concat">
                            <el-input v-model.trim="form.pic_concat"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="排序" prop="sort">
                            <el-input v-model.trim="form.sort" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="单位地址" prop="address">
                        <el-input v-model.trim="form.address" :rows="4" type="textarea"></el-input>
                    </el-form-item>
                </el-row>
            </el-row>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "添加/修改"
            },
            name: {
                default: null
            },
            companyLevel:{
                default: null
            },
        },
        data() {
            return {
                companyList: [],
                levelList: [
                    {name: "一级单位", id: 1},
                    {name: "二级单位", id: 2},
                    {name: "三级单位", id: 3},
                    {name: "四级单位", id: 4},
                    {name: "五级单位", id: 5},
                ],
                level: null
            }
        },
        methods: {
            handelShow(item) {
                if (this.$store.getters.userType == '1') {
                    return true
                } else {
                    console.log(item.id,this.level)
                    if (item.id > this.level && item.id > this.companyLevel) {
                        return true
                    }
                    return false
                }
            },
            async handelCompany(level) {
                if (level && level != "1") {
                    const resp = await this.$http.get(`/company/company?page=1&pageSize=500&level=${level - 1}`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.companyList = resp.data.data
                    }
                }
            },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    let url = `/company/company`
                    const resp = await this.$http.post(url, this.form)
                    this.$message[resp.data.status](resp.data.msg)
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
            handleOpen() {
                this.$http.get(`/company/company/info`)
                    .then(resp => {
                        this.level = resp.data.data.level
                        console.log(this.level)
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
